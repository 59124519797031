import { CodeSVG } from "../../images";
import { routesPaths } from "../../routing";

import JsonYaml from "./JsonYaml";

const Tools = {
  jsonYaml: {
    name: "JSON <> YAML",
    route: routesPaths.jsonYaml,
    description: "Transform structured data between the JSON and YAML formats",
    categories: ["formatting", "text"],
    svg: CodeSVG,
  },
  currencyPIT: {
    name: "Currency PIT",
    route: routesPaths.currencyPIT,
    description: "Convert currency rates to the past",
    categories: ["category1"],
    svg: CodeSVG,
  },
};

const AllTools = [
  Tools.jsonYaml,
  // Tools.currencyPIT
];

export { JsonYaml as FormatJson, Tools };
export default AllTools;
