import { Link as RouterLink } from "react-router-dom"
import { Box, Link } from "@mui/joy"
import ColorSchemeSwitchButton from "./ColorSchemeSwitchButton"

const Navbar = () => {
    return (
        <Box sx={{
            paddingTop: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}>
            <Link
                underline="none"
                variant="plain"
                color="neutral"
                component={RouterLink}
                to="/"
                fontWeight="lg"
                sx={{
                    '&:hover': {
                        background: 'transparent',
                    }
                }}
                startDecorator={
                    <Box
                        component="span"
                        sx={{
                            width: 40,
                            height: 40,
                            marginRight: 1,
                            background: (theme) =>
                                `linear-gradient(45deg, ${theme.vars.palette.primary.solidBg}, ${theme.vars.palette.primary.solidBg} 30%, ${theme.vars.palette.primary.softBg})`,
                            borderRadius: '50%',
                            boxShadow: (theme) => theme.shadow.md,
                            '--joy-shadowChannel': (theme) =>
                                theme.vars.palette.primary.mainChannel,
                        }}
                    />
                }
            >
                tools
            </Link>

            <ColorSchemeSwitchButton></ColorSchemeSwitchButton>
        </Box>
    )
}

export default Navbar