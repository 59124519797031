import { PropsWithChildren } from "react";

import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import Navbar from "../components/Navbar";
import { Alert, Container } from "@mui/joy";

interface DefaultLayoutProps extends PropsWithChildren {
}

const DefaultLayout = (props: DefaultLayoutProps) => {

    return (
        <CssVarsProvider>
            <CssBaseline />

            <Container maxWidth="lg">
                <Navbar />
                <Alert variant="outlined" sx={{ marginTop: 4 }}>
                    Your data never leaves your browser, every tools is working in offline mode.
                </Alert>
                {props.children}
            </Container>
        </CssVarsProvider >
    )
}

export default DefaultLayout;