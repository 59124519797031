import { Link as RouteLink } from "react-router-dom";
import { Box, Button, Link, Typography } from "@mui/joy";
import DefaultLayout from "../../layouts/DefaultLayout";
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';

const Error404 = () => {
    return (
        <DefaultLayout>
            <Box
                sx={{
                    paddingTop: "25vh",
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                }}>
                <Typography level="h2" fontSize="xl">
                    Page Not Found.
                </Typography>
                <Link
                    sx={{
                        marginTop: 5,
                    }}
                    to="/" component={RouteLink} textColor="common.white">
                    <Button
                        startDecorator={<KeyboardArrowLeftOutlinedIcon />}>
                        <Typography>
                            Go back
                        </Typography>
                    </Button>
                </Link>
                {/* <Box
                    sx={{
                        width: "100%",
                        height: "500px",
                        backgroundSize: "cover",
                        // backgroundImage: "url(https://images.unsplash.com/photo-1508138221679-760a23a2285b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80)"
                    }}>
                    <Box
                        sx={{
                            width: "100%",
                            height: "500px",
                            background: "rgba(0, 0, 0, 0.5)",

                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                            alignItems: "center",
                            alignContent: "center",
                            justifyContent: "center",
                        }}>
                        <Typography level="h2" fontSize="xl">
                            Page Not Found.
                        </Typography>
                        <Link
                            sx={{
                                marginTop: 2,
                            }}
                            to="/" component={RouteLink} textColor="common.white">
                            <Button
                                startDecorator={<KeyboardArrowLeftOutlinedIcon />}>
                                <Typography>
                                    Go back
                                </Typography>
                            </Button>
                        </Link>
                    </Box>
                </Box>
                    */}
            </Box>
        </DefaultLayout >
    );
};

export default Error404;