import React from "react";
import { createContext } from "react";
import useMediaQuery from '@mui/material/useMediaQuery';

interface IState {
    colorMode: 'light' | 'dark';
}

interface IPayload {
    colorMode: 'light' | 'dark';
}

interface IAction {
    type: string;
    payload: IPayload;
}

export interface IColorModeContext {
    context: IState;
    dispatch: React.Dispatch<IAction>;
}

export const ColorModeContext = createContext<IColorModeContext>({
    context: { colorMode: 'light' },
    dispatch: () => undefined
});

export const ColorModeActions = {
    Switch: 'COLOR_MODE_ACTION_SWITCH',
}

const ColorModeReducer = function (state: IState, action: IAction): IState {
    switch (action.type) {
        case ColorModeActions.Switch:
            localStorage.setItem('theme', action.payload.colorMode);
            return {
                ...state,
                colorMode: action.payload.colorMode
            }
        default:
            return state;
    }
}

export const ColorModeProvider = function ({ children }: any) {
    const savedTheme = localStorage.getItem('theme');

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const preferredMode = savedTheme ?? prefersDarkMode ? 'dark' : 'light';

    const initState: IState = {
        colorMode: preferredMode
    }

    const [context, dispatch] = React.useReducer(ColorModeReducer, initState);

    return (
        <ColorModeContext.Provider value={{ context, dispatch }}>
            {children}
        </ColorModeContext.Provider>
    )
}