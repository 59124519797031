import axios from 'axios'
import React, { createContext, Reducer, useReducer } from 'react'

interface IState {
    id: string | undefined;
    token: string | undefined;
    username: string | undefined;
    refreshToken: string | undefined;
    lastRefresh: number;
    authorized: boolean;
}

interface IPayload {
    id: string;
    token: string;
    username: string;
    refreshToken: string;
}

interface IAction {
    type: string;
    payload: Partial<IPayload>;
}

export interface IUserContext {
    context: Partial<IState>
    dispatch: React.Dispatch<IAction>
}

export const UserContext = createContext<IUserContext>({
    context: {},
    dispatch: () => undefined
});

const initState: IState = {
    id: '',
    token: '',
    username: '',
    lastRefresh: -1,
    refreshToken: '',
    authorized: false,
}

export const UserActions = {
    Login: 'USER_ACTION_LOGIN',
    LoginFailed: 'USER_ACTION_LOGIN_FAILED',
    Logout: 'USER_ACTION_LOGOUT',
    Refresh: 'USER_ACTION_REFRESH'
}

const UserReducer = function (state: IState, action: IAction): IState {
    switch (action.type) {
        case UserActions.Login:
            const userState: IState = {
                authorized: true,
                id: action.payload.id,
                lastRefresh: Date.now(),
                token: action.payload.token,
                username: action.payload.username,
                refreshToken: action.payload.refreshToken,
            }

            localStorage.setItem('UserState', JSON.stringify(userState))

            return userState
        case UserActions.Refresh:
            const refreshedState: IState = {
                id: state.id,
                authorized: true,
                lastRefresh: Date.now(),
                username: state.username,
                token: action.payload.token,
                refreshToken: action.payload.refreshToken
            }

            localStorage.setItem('UserState', JSON.stringify(refreshedState))

            return refreshedState
        case UserActions.LoginFailed:
            localStorage.removeItem('UserState')

            axios.defaults.headers.common = {}
            return initState
        case UserActions.Logout:

            localStorage.removeItem('UserState')

            axios.defaults.headers.common = {}
            return initState
        default:
            return state
    }
}

export const UserProvider = function ({ children }: { children: any }) {
    const localState = localStorage.getItem('UserState')

    var startState = initState;

    if (localState != null) {
        startState = JSON.parse(localState);
    }

    const [context, dispatch] = useReducer<Reducer<IState, IAction>>(UserReducer, startState);

    return (
        <UserContext.Provider value={{ context, dispatch }}>
            {children}
        </UserContext.Provider>
    )
}