import { Box, Button, IconButton } from "@mui/joy";
import { useRef, useState } from "react";
import GetAppIcon from "@mui/icons-material/GetApp";
import UploadIcon from "@mui/icons-material/Upload";
import ToolLayout from "../../layouts/ToolLayout";
import { Tools } from "./index";
import Papa from "papaparse";

const CurrencyPIT = () => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [csvContent, setCsvContent] = useState<string | null>(null);
  const [totalPIT, setTotalPIT] = useState<number>(0);

  const onClickDownload = () => {
    throw new Error("Function not implemented.");
  };

  const onChangeUpload = () => {
    if (fileInputRef.current !== null) {
      const files = fileInputRef.current.files;

      if (files !== null && files.length > 0 && files[0] !== null) {
        const file = files[0];
        file.text().then((text) => {
          setCsvContent(text);
        });
      }

      fileInputRef.current.value = "";
    }
  };

  const onClickCalculate = async () => {
    const ratesResponse = await fetch(
      "https://api.nbp.pl/api/exchangerates/rates/a/eur/2023-01-01/2023-12-31?format=json"
    );
    const rates = await ratesResponse.json();
    const internalRates = rates["rates"];

    const ratesByDate: any = {};

    for (let i = 0; i < internalRates.length; i++) {
      ratesByDate[internalRates[i]["effectiveDate"]] = internalRates[i]["mid"];
    }

    console.log(ratesByDate);

    if (csvContent == null) {
      return;
    }

    const results = Papa.parse(csvContent);

    let total = 0.0;

    let lastAvailableDayRate = 0.0;

    for (let i = 1; i < results.data.length; ++i) {
      const row = results.data[i] as Array<string>;

      if (row.length < 7) {
        continue;
      }

      const date = new Date(row[0]);
      const moneyIn = parseFloat(row[5].replaceAll("+€", ""));
      const isDeposit = row[2] === "Deposit";

      if (isDeposit || Number.isNaN(moneyIn)) {
        continue;
      }

      let day = date.toISOString().split("T")[0];
      const firstDate = date.toISOString().split("T")[0];

      /* while (!(day in ratesByDate)) {
        date.setDate(date.getDate() - 1);
        day = date.toISOString().split("T")[0];

        console.log(`new date: ${date}`);
      } */

      if (day in ratesByDate) {
        const rateThisDay = ratesByDate[day];

        console.log(`rate of ${firstDate} is from ${day}: ${rateThisDay}`);

        total += moneyIn * rateThisDay;

        lastAvailableDayRate = rateThisDay;
      } else {
        total += moneyIn * lastAvailableDayRate;

        console.log(
          `for ${firstDate} using last available: ${lastAvailableDayRate}`
        );
      }
    }

    total *= 0.19;

    setTotalPIT(total);

    // Papa.parse(event.target.files[0], {
    //   header: true,
    //   skipEmptyLines: true,
    //   complete: function (results) {
    //     console.log(results.data);
    //   },
    // });

    // setCsvContent(ratesString);
  };

  const renderContent = () => {
    return <p>{totalPIT.toFixed(2)}</p>;
  };

  return (
    <ToolLayout tool={Tools.currencyPIT}>
      <Box
        sx={{
          pt: 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      ></Box>
      <Box
        sx={{
          pt: 5,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <IconButton sx={{ marginX: 1 }} onClick={onClickDownload}>
          <GetAppIcon />
        </IconButton>
        <IconButton sx={{ marginX: 1 }} component="label">
          <UploadIcon />
          <input
            ref={fileInputRef}
            hidden
            accept={".json,.yaml,.yml"}
            type="file"
            onChange={onChangeUpload}
          />
        </IconButton>
        <Button sx={{ marginX: 1 }} onClick={onClickCalculate}>
          Calculate
        </Button>
      </Box>
      <Box
        sx={{
          pt: 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {renderContent()}
      </Box>
    </ToolLayout>
  );
};

export default CurrencyPIT;
