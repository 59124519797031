import { Link as RouteLink } from "react-router-dom";
import { Box, Button, Link, Typography } from "@mui/joy";
import { PropsWithChildren } from "react"
import ITool from "../types/ITool"
import DefaultLayout from "./DefaultLayout"
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';

interface ToolLayoutProps extends PropsWithChildren {
    tool: ITool
}

const ToolLayout = (props: ToolLayoutProps) => {
    return (
        <DefaultLayout>
            <Box
                sx={{
                    pt: 5,
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center'
                }}>
                <Box sx={{
                    flex: 1
                }}>
                    <Link to="/" component={RouteLink} textColor="common.white">
                        <Button
                            startDecorator={<KeyboardArrowLeftOutlinedIcon />}
                            variant="outlined">
                            <Typography textAlign="center">
                                Go back
                            </Typography>
                        </Button>
                    </Link>
                </Box>
                <Typography level="h2" sx={{
                }}>
                    {props.tool.name}
                </Typography>
                <Box sx={{ flex: 1 }}></Box>
            </Box>
            {props.children}
        </DefaultLayout>
    )
}

export default ToolLayout