import CurrencyPIT from "./pages/tools/CurrencyPIT";
import JsonYaml from "./pages/tools/JsonYaml";

const routesPaths = {
  formatJson: "/format-json",
  formatYaml: "/format-yaml",
  jsonYaml: "/json-yaml",
  currencyPIT: "/currency-pit",
};

const ROUTES = {
  tools: [
    {
      element: <JsonYaml />,
      path: routesPaths.jsonYaml,
    },
    {
      element: <JsonYaml />,
      path: routesPaths.formatJson,
    },
    {
      element: <JsonYaml />,
      path: routesPaths.formatYaml,
    },
    {
      element: <CurrencyPIT />,
      path: routesPaths.currencyPIT,
    },
  ],
};

export { routesPaths, ROUTES };
