import { Box, Input, IconButton, Typography } from "@mui/joy";
import ITool from "../types/ITool";
import ToolCard from "./ToolCard";
import { ClearRounded, SearchRounded } from "@mui/icons-material";
import { useState } from "react";
import AllTools from "../pages/tools";

const ToolsList = () => {
    const [filteredTools, setFilteredTools] = useState<{ tools: ITool[], filter: string }>({ tools: AllTools, filter: "" });

    const handleOnChange = (e: any) => {
        const newTools: ITool[] = [];

        const newFilter = e.target.value

        for (const tool of AllTools) {
            if (tool.name.toLowerCase().includes(newFilter.toLowerCase())) {
                newTools.push(tool);
            }
        }

        setFilteredTools(
            {
                tools: newTools,
                filter: newFilter
            }
        );
    };

    const renderFilteredTools = () => {
        if (filteredTools.tools.length === 0) {
            return (
                <Box mt={4}>
                    <Typography textAlign="center" textColor="neutral.400">Not found any tool matching criteria.</Typography>
                </Box>
            )
        }

        return (
            <Box
                sx={{
                    paddingTop: "2em",
                    display: 'grid',
                    alignItems: "center",
                    justifyContent: "space-between",
                    gridGap: "1em",
                    gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))'
                }}>
                {filteredTools.tools.map((tool: ITool) => <ToolCard key={tool.route} tool={tool} />)}
            </Box >
        )
    }

    return (
        <Box>
            <Input
                value={filteredTools.filter}
                placeholder="Search…"
                onChange={handleOnChange}
                sx={{
                    marginTop: 4,
                    marginX: 'auto',
                    maxWidth: '500px',
                    flexBasis: '500px',
                    display: {
                        xs: 'flex',
                    }
                }}
                startDecorator={
                    <SearchRounded />
                }
                endDecorator={
                    <IconButton
                        variant="plain"
                        color="neutral"
                        onClick={() => {
                            setFilteredTools(
                                {
                                    tools: AllTools,
                                    filter: ""
                                }
                            );
                        }}>
                        <ClearRounded />
                    </IconButton >
                }>
            </Input>

            {renderFilteredTools()}
        </Box>
    )
}

export default ToolsList;