import { useColorScheme } from "@mui/joy/styles"
import { Editor, EditorProps } from "@monaco-editor/react";
import { Card } from "@mui/joy";

interface StyledEditorProps extends EditorProps {
}

const StyledEditor = (props: StyledEditorProps) => {
    const { colorScheme } = useColorScheme();

    const theme = colorScheme === 'dark' ? 'vs-dark' : 'light';

    return (
        <Card>
            <Editor {...props} theme={theme} options={{ minimap: { enabled: false } }} />
        </Card>
    )
}

export default StyledEditor;