import { Link as RouterLink } from "react-router-dom"
import { Box, Card, CardContent, Typography, Chip, Link } from "@mui/joy";
import ITool from "../types/ITool";

interface ToolCardProps {
    tool: ITool;
}

const ToolCard = (props: ToolCardProps) => {
    const tool = props.tool;

    return (
        <Card
            sx={{
                height: '100%',
                transition: 'box-shadow 0.3s ease-in-out',
                '&:hover': { boxShadow: 'md', borderColor: 'neutral.solidHoverBorder' },
            }}>
            <CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}>
                    {tool.categories.map((category: string) => {
                        return (
                            <Chip
                                key={category}
                                variant="outlined"
                                color="primary"
                                size="sm"
                                sx={{ marginLeft: 1, pointerEvents: 'none' }}>
                                {category}
                            </Chip>
                        )
                    })}
                </Box>
                <Box
                    sx={{
                        justifyContent: 'center',
                        marginY: 2,
                        display: 'flex',
                        maxHeight: '64px',
                    }}>
                    <Link overlay component={RouterLink} to={tool.route}></Link>
                    <tool.svg width={64} height={64} />
                </Box>
                <Box
                    sx={{
                        display: 'block',
                        justifyContent: 'flex-end',
                        // height: '50px',
                    }}>
                    <Typography level="h2" fontSize="lg" mb={1}>
                        {tool.name}
                    </Typography>
                    <Typography textColor="neutral.300">
                        {tool.description}
                    </Typography>
                </Box>
            </CardContent>
        </Card >
    )
}

export default ToolCard;