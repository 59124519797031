import { IconButton } from "@mui/joy";
import { useColorScheme } from '@mui/joy/styles';
import { DarkMode, LightMode } from "@mui/icons-material";
import { DefaultColorScheme } from "@mui/joy/styles/types";

const ColorSchemeSwitchButton = () => {
    const { colorScheme, setColorScheme } = useColorScheme();

    const renderButton = (colorScheme: DefaultColorScheme | undefined) => {
        if (colorScheme === "light") {
            return <DarkMode />;
        } else {
            return <LightMode />;
        }
    }

    return (
        <IconButton
            onClick={() => {
                setColorScheme(colorScheme === "light" ? "dark" : "light");
            }}
            variant="plain"
            color="neutral"
        >
            {renderButton(colorScheme)}
        </IconButton>
    );
};

export default ColorSchemeSwitchButton;