import { Box, Button, IconButton } from "@mui/joy";
import { useEffect, useRef, useState } from "react";
import GetAppIcon from "@mui/icons-material/GetApp";
import UploadIcon from "@mui/icons-material/Upload";
import StyledEditor from "../../components/StyledEditor";
import ToolLayout from "../../layouts/ToolLayout";
import { Tools } from "./index";
import jsyaml from "js-yaml";

const JsonYaml = () => {
  const editorRef = useRef<any>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [language, setLanguage] = useState<string>("json");

  useEffect(() => {
    fileInputRef.current?.focus();
  }, []);

  const onEditorMount = (editor: any, monaco: any) => {
    editorRef.current = editor;
  };

  const getSourceCodeLanguage = () => {
    const value = editorRef.current.getValue();

    let sourceCodeLanguage: "json" | "yaml" | null;

    try {
      JSON.parse(value);
      sourceCodeLanguage = "json";
    } catch (e) {
      sourceCodeLanguage = null;
    }

    if (sourceCodeLanguage === null) {
      try {
        jsyaml.load(value) as string;
        sourceCodeLanguage = "yaml";
      } catch (e) {
        sourceCodeLanguage = null;
      }
    }

    return sourceCodeLanguage;
  };

  const onFormatClick = () => {
    const editor = editorRef.current;

    const value = editor.getValue();

    switch (getSourceCodeLanguage()) {
      case "json":
        const parsedValue = JSON.parse(value);
        const resultJson = JSON.stringify(parsedValue, null, 4);

        editor.setValue(resultJson);
        setLanguage("json");
        break;
      case "yaml":
        const parsedYaml = jsyaml.load(value) as string;
        const resultYaml = jsyaml.dump(parsedYaml);

        editor.setValue(resultYaml);
        setLanguage("yaml");
        break;
      default:
        console.log("show error!");
        break;
    }
  };

  const onMinifyClick = () => {
    const editor = editorRef.current;

    const value = editor.getValue();

    switch (getSourceCodeLanguage()) {
      case "json":
        const parsedValue = JSON.parse(value);
        const resultJson = JSON.stringify(parsedValue, null, 0);

        editor.setValue(resultJson);
        setLanguage("json");
        break;
      case "yaml":
        const opts: jsyaml.DumpOptions = {
          condenseFlow: true,
          flowLevel: 1,
        };
        const parsedYaml = jsyaml.load(value) as string;
        const resultYaml = jsyaml.dump(parsedYaml, opts);

        editor.setValue(resultYaml);
        setLanguage("yaml");
        break;
      default:
        console.log("show error!");
        break;
    }
  };

  const onConvertClick = () => {
    const editor = editorRef.current;

    const value = editor.getValue();

    try {
      switch (getSourceCodeLanguage()) {
        case "json":
          const parsedValue = JSON.parse(value);
          const resultYaml = jsyaml.dump(parsedValue);

          setLanguage("yaml");
          editor.setValue(resultYaml);
          break;
        case "yaml":
          const parsedYaml = jsyaml.load(value) as string;
          const resultJson = JSON.stringify(parsedYaml, null, 4);

          editor.setValue(resultJson);
          setLanguage("json");
          break;
        default:
          console.log("show error!");
          break;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onDownloadClick = () => {};

  const onChangeUpload = () => {
    if (fileInputRef.current !== null) {
      const files = fileInputRef.current.files;

      if (files !== null && files.length > 0 && files[0] !== null) {
        const file = files[0];
        file.text().then((text) => {
          editorRef.current.setValue(text);
          setLanguage(file.name.split(".").pop() === "json" ? "json" : "yaml");
        });
      }

      fileInputRef.current.value = "";
    }
  };

  return (
    <ToolLayout tool={Tools.jsonYaml}>
      <Box
        sx={{
          pt: 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      ></Box>
      <Box
        sx={{
          pt: 5,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <IconButton sx={{ marginX: 1 }} onClick={onDownloadClick}>
          <GetAppIcon />
        </IconButton>
        <IconButton sx={{ marginX: 1 }} component="label">
          <UploadIcon />
          <input
            ref={fileInputRef}
            hidden
            accept={".json,.yaml,.yml"}
            type="file"
            onChange={onChangeUpload}
          />
        </IconButton>
        <Button sx={{ marginX: 1 }} onClick={onFormatClick}>
          Format
        </Button>
        <Button sx={{ marginX: 1 }} onClick={onMinifyClick}>
          Minify
        </Button>
        <Button sx={{ marginX: 1 }} onClick={onConvertClick}>
          Convert
        </Button>
      </Box>
      <Box
        sx={{
          pt: 5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <StyledEditor
          height={"40vh"}
          language={language}
          onMount={onEditorMount}
        />
      </Box>
    </ToolLayout>
  );
};

export default JsonYaml;
