import ToolsList from "../components/ToolsList";
import DefaultLayout from "../layouts/DefaultLayout";

const Home = () => {
    return (
        <DefaultLayout>
            <ToolsList />
        </DefaultLayout>
    )
}

export default Home;