import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { UserProvider } from '../contexts/UserContext';
import { ColorModeProvider } from '../contexts/ColorModeContext';

import Home from '../pages/Home';
import Error404 from '../pages/errors/Error404';

import { ROUTES } from '../routing';

import '@fontsource/public-sans';


const App = () => {
  return (
    <UserProvider>
      <ColorModeProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            {ROUTES.tools.map((route) => <Route key={route.path} path={route.path} element={route.element} />)}
            <Route path='*' Component={Error404}></Route>
          </Routes>
        </BrowserRouter>
      </ColorModeProvider>
    </UserProvider>
  );
}

export default App;